import { useRef, useCallback } from 'react';

/**
 * body scroll lock을 걸었으나 하위 특정 요소 트리에는 스크롤을 허용하고 싶을 때 사용
 */
export function useAllowTouchMove() {
  const innerRef = useRef<HTMLElement | null>(null);

  /**
   * 스크롤을 허용하고 싶은 대상에게 부여
   */
  const scrollableElementRef = useCallback((node: HTMLElement | null) => {
    const handleTouchMove = (event: TouchEvent) => {
      event.stopPropagation(); // Allow scroll but prevent propagation to avoid body scroll lock.
    };

    if (innerRef.current) {
      innerRef.current.removeEventListener('touchmove', handleTouchMove);
    }

    if (node) {
      node.addEventListener('touchmove', handleTouchMove, { passive: false });
    }

    innerRef.current = node;
  }, []);

  return scrollableElementRef;
}
