import styled from '@emotion/styled';
import { FC } from 'react';

interface Props {
  className?: string;
}

const Container = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.red500};
  border-radius: 100px;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

const NewBadge: FC<Props> = ({ className }) => {
  return <Container className={className}>N</Container>;
};

export default NewBadge;
