import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import AnimatedText from 'src/components/AnimatedText';
import Gap from 'src/components/Gap';
import { TABLET_HEADER_HEIGHT } from 'src/components/Header/style';
import Watermark from 'src/components/Logo/Watermark';
import MatchIntro from 'src/components/Match/Intro';
import { FilterIcon } from 'src/components/Match/Preference/Button';
import {
  GenderButtonBase,
  GenderButtonProps,
  GenderIconBase,
} from 'src/components/Match/Preference/Gender';
import {
  LocationButtonBase,
  LocationButtonProps,
  LocationIconBase,
} from 'src/components/Match/Preference/Location';
import ResizingText from 'src/components/ResizingText';
import useEndMatch from 'src/hooks/match/useEndMatch';
import useMatchHeader from 'src/hooks/match/useMatchHeader';
import useNextMatch from 'src/hooks/match/useNextMatch';
import useBeforeunload from 'src/hooks/useBeforeunload';
import useBodyScrollLock from 'src/hooks/useBodyScrollLock';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import useGrantVideo from 'src/hooks/useGrantVideo';
import useIsOpenModal from 'src/hooks/useIsOpenModal';
import useLogin from 'src/hooks/useLogin';
import useVisibilityChange from 'src/hooks/useVisibilityChange';
import {
  antmanCapturedAtom,
  clearMatchAtom,
  endMatchDisableShowAtom,
  isBlockedPeerVideoAtom,
  isVideoObjectFitCoverAtom,
  matchInfoAtom,
  matchStatAtom,
  mobileLayoutAtom,
  peerAudioLevelAtom,
  peerCameraEnabledAtom,
  peerVidRefAtom,
  reportBeforeUnloadAtom,
  sourceStreamAtom,
  statusAtom,
  stepAtom,
  swipeDisableDurationAtom,
  swipeDisableSecondAtom,
} from 'src/stores/match/atoms';
import { MobileLayout, STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import getDeviceInfo from 'src/utils/device/info';

import AntmanWarning from './AntmanWarning';
import BottomMatchCounter from './BottomMatchCounter';
import ChatList from './ChatList';
import DesktopMatchFooter from './DesktopMatchFooter';
import DesktopMatchUuid from './DesktopMatchUuid';
import MyVideoPolygon from './MyVideoPolygon';
import PeerHeroVideo from './PeerHeroVideo';
import ConnectedPeerProfile from './PeerProfile/Connected';
import StartingPeerProfile from './PeerProfile/Starting';
import PeerVideoPolygon from './PeerVideoPolygon';
import ReportButton from './Report/Button';
import Toolbar from './Toolbar';
import VideoLayer from './VideoLayer';
import { mouseHoverCss } from 'src/utils/styled';
import { isUseKeepAliveAtom } from 'src/network/atoms';
import useInitDecoEffect from 'src/hooks/useInitDecoEffect';
import BaseImage from 'src/components/Image';
import useOpenEffectByUrlQuery from 'src/hooks/useOpenEffectByUrlQuery';

const ButtonStyleCommon = ({ theme }: { theme: Theme }) => css`
  ${FilterIcon} {
    fill: ${theme.color.gray600__dkGray500};
  }
  ${mouseHoverCss(`
    background-color: ${theme.color.gray30__dkGray50};
    color: ${theme.color.gray900__dkGray970};
  `)}
`;

const ButtonStyleDesktop = ({ theme }: { theme: Theme }) => css`
  ${ButtonStyleCommon({ theme })}
  font-size: 20px;
  font-weight: 700;
  display: inline-flex;
  width: 182px;
  height: 58px;
  color: ${theme.color.gray30__dkGray50};
  background-color: ${theme.color.gray900__dkGray970};
  ${theme.screenSize.tablet} {
    display: none;
  }
`;

const ButtonStyleMobile = ({ theme }: { theme: Theme }) => css`
  ${ButtonStyleCommon({ theme })}
  font-size: 16px;
  font-weight: 700;
  width: 140px;
  height: 48px;
  color: ${theme.color.gray30__dkGray50};
  background-color: ${theme.color.gray900__dkGray970};
`;

const GenderButton = styled(GenderButtonBase)`
  ${ButtonStyleDesktop}
`;

const LocationButton = styled(LocationButtonBase)`
  ${ButtonStyleDesktop}
`;

const GenderButtonMobile = styled(GenderButtonBase)`
  ${ButtonStyleMobile}
`;

const LocationButtonMobile = styled(LocationButtonBase)`
  ${ButtonStyleMobile}
`;

const BottomWrap = styled(AnimatedText)`
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
`;

const BottomWrapRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const BottomWrapGap = styled.div`
  width: 16px;
  display: flex;
  flex-shrink: 0;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 10px;
  }
`;

const BottomWrapMobile = styled(AnimatedText)`
  display: none;
  ${({ theme }) => theme.screenSize.tablet} {
    position: absolute;
    bottom: 24px;
    display: flex;
  }
`;

const ButtonIconBase = ({ theme }: { theme: Theme }) => css`
  width: 26px;
  height: 26px;
  ${theme.screenSize.tablet} {
    width: 20px;
    height: 20px;
  }
`;
const GenderIcon = styled(GenderIconBase)`
  ${ButtonIconBase}
`;

const LocationIcon = styled(LocationIconBase)`
  ${ButtonIconBase}
`;

const RootKeyframe = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  1% {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const RootKeyframeBack = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  99.9% {
    opacity: 0;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

const Root = styled.div<{ step: number; status: STATUS }>`
  display: flex;
  height: 100%;
  width: var(--100vw);
  background-color: ${({ theme }) => theme.color.gray30__dkGray50};
  &:focus {
    outline: none;
  }
  padding-top: 64px;
  transition: padding-top 0.5s 0.5s;
  ${({ theme }) => theme.screenSize.tablet} {
    padding-top: 0;
  }
`;
const Wrap = styled.div<{ status: STATUS }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  width: ${({ status }) =>
    status == STATUS.MATCHED ? 'calc(100% - 316px)' : '100%'};
  transition: width 0.5s;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 100%;
  }
`;

const Background = styled.div`
  z-index: -1;
  position: absolute;
  background-position: center;
  background-size: 150%;
`;

const Header = styled.div`
  margin-bottom: 16px;
  ${({ theme }) => theme.screenSize.tablet} {
    margin-bottom: 0px;
    width: 100%;
    padding: 16px 16px 0 16px;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const DesktopChattingHeader = styled.div`
  width: 100%;
  height: 80px;
  padding: 20px 16px;
  box-shadow: inset 0px -1px 0px #333333;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
`;

const TabletTopHeader = styled(Header)`
  width: 100%;
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
`;

const HeaderInner = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Hero = styled(AnimatedText)`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Picture = styled.picture`
  max-width: 100%;
`;

const Image = styled(BaseImage)`
  width: 100%;
  max-width: 456px;
  height: auto;
  max-height: calc(var(--100vh) - 456px);
  ${({ theme }) => theme.screenSize.tablet} {
    max-width: 260px;
    max-height: 260px;
  }
`;

const PeerVideo = styled.canvas<{ isShow: boolean; isObjectFitCover: boolean }>`
  position: absolute;
  object-fit: ${({ isObjectFitCover }) =>
    isObjectFitCover ? 'cover' : 'contain'};
  width: 100%;
  height: 100%;
  ${({ isShow }) =>
    !isShow &&
    `
    display: none;
  `}
`;

const HeaderItem = styled.div`
  display: inline-flex;
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
`;
const HeaderText = styled(AnimatedText)`
  font-weight: 700;
  display: inline-flex;
  font-size: 50px;
  color: ${({ theme }) => theme.color.white};
  width: 100%;
  justify-content: center;
  ${({ theme }) => theme.screenSize.desktop} {
    padding-left: 40px;
    padding-right: 40px;
  }
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 26px;
    justify-content: normal;
  }
`;
const HeaderText2 = styled(HeaderText)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 0;
    transform: none;
  }
`;

const SwipeDisableLayer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: white;
  overflow: hidden;
`;

const IcBack = styled(AnimatedText)`
  z-index: 1;
  width: 32px;
  height: 32px;
  background-size: 24px;
  transition: opacity 0.5s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  > div,
  > div > div {
    width: inherit;
    height: inherit;
  }
`;

const IcBackButton = styled.button`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('/images/icons/icMatchClose.svg');
  background-position: center;
  background-color: transparent;
  background-repeat: no-repeat;
`;

const ChattingContainer = styled.div<{
  step: number;
  status: STATUS;
  hide: boolean;
}>`
  padding: ${({ status, theme }) => {
    if (status < STATUS.MATCHED) {
      return '0';
    }
    return theme.isRtl ? '0 0 16px 16px' : '0 16px 16px 0';
  }};
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 0px;
  transition:
    width 0.5s,
    padding 0.5s;
  ${({ step, status }) =>
    step === 1 &&
    status > STATUS.MATCHED &&
    `
      width: 0px;
    `}
  ${({ status }) => {
    switch (status) {
      case STATUS.CONNECTING: {
        return `
          display: flex;
        `;
      }
      case STATUS.MATCHED: {
        return `
          display: flex;
          width: 316px;
        `;
      }
      default: {
        return `
          display: none;
        `;
      }
    }
  }}
  ${({ theme }) => theme.screenSize.tablet} {
    padding: 0;
    position: fixed;
    bottom: 0px;
    left: 0;
    display: ${({ hide }) => hide && 'none'};
    height: 360px;
    z-index: 11;
    width: 100%;
    animation: none;
    box-shadow: none;
    background-color: transparent;
  }
`;
const ChatBar = styled.div`
  background-color: ${({ theme }) => theme.color.gray60__dkGray100};
  border-radius: 20px;
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.screenSize.tablet} {
    background-color: transparent;
    border-radius: 0;
  }
`;
const ChattingWrap = styled.div<{ step: number; status: STATUS }>`
  opacity: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${({ step }) =>
    step === 1 &&
    css`
      animation: ${RootKeyframeBack} 0.5s linear forwards;
    `}
  ${({ status }) =>
    status === STATUS.MATCHED &&
    css`
      animation: ${RootKeyframe} 0.5s linear 1s forwards;
    `}
`;

const dotKeyframes = [0, 1, 2].map(
  (index) => keyframes`
  from {
    opacity: 0;
  }
  ${index * (1 / 4) * 100 + '%'}  {
    opacity: 0;
  }
  ${(index + 1) * (1 / 4) * 100 + '%'}  {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`
);

const Dot = styled.span<{ index: 0 | 1 | 2 }>`
  ${({ index }) =>
    index !== undefined &&
    css`
      opacity: 0;
      animation: ${dotKeyframes[index]} ease-out 1500ms infinite;
      animation-delay: 0.1s;
    `}
`;

const rota = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const opa = keyframes`
  0% { opacity: 1; }
  50%, 100% { opacity: 0; }
`;

const SwipeDisablePie = styled.div`
  width: 50%;
  height: 100%;
  transform-origin: 100% 50%;
  position: absolute;
  background-color: #baf4e2;
`;

interface SwipeDisableProps {
  duration: number;
}

const SwipeDisableSpinner = styled(SwipeDisablePie)<SwipeDisableProps>`
  left: 0;
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  z-index: 5;
  border-right: none;
  animation: ${rota} ${({ duration }) => duration}s linear infinite;
`;

const SwipeDisableFiller = styled(SwipeDisablePie)<SwipeDisableProps>`
  border-radius: 0 100% 100% 0 / 0 50% 50% 0;
  left: 50%;
  opacity: 0;
  z-index: 4;
  animation: ${opa} ${({ duration }) => duration}s steps(1, end) infinite
    reverse;
  border-left: none;
`;

const SwipeDisableMask = styled.div<SwipeDisableProps>`
  left: 0;
  width: 50%;
  height: 100%;
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  position: absolute;
  background: inherit;
  opacity: 1;
  z-index: 6;
  animation: ${opa} ${({ duration }) => duration}s steps(1, end) infinite;
`;

const SwipeDisableText = styled.div`
  z-index: 7;
  font-size: 13px;
  font-weight: 700;
`;

const VideoWrap = styled.div<{ step: number }>`
  position: relative;
  display: flex;
  height: 100%;
  padding: 0 16px 16px;
  ${({ step }) =>
    step === 2 &&
    `
    padding: 0 16px;
    height: calc(100% - 88px);
  `}
  transition: padding .5s .5s, height .5s .5s;
  ${({ theme }) => theme.screenSize.tablet} {
    padding: 0;
    height: 100%;
  }
`;

const VideoGap = styled(Gap)`
  transition: width 0.5s 0.5s;
`;

const EarthAnimator = styled(AnimatedText)<{ mobileLayout: MobileLayout }>`
  margin-bottom: 38px;
  width: 456px;
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ mobileLayout }) =>
      mobileLayout === 'DEFAULT'
        ? `
      width: 300px;
    `
        : `
      width: 180px;
    `}
  }
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
`;

const BottomPictureInfoWrap = styled.div<{ hasStream: boolean }>`
  z-index: 1;
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 0;
  bottom: 0;
  display: flex;
  padding: 32px 40px;

  ${({ theme }) => theme.screenSize.tablet} {
    ${({ hasStream }) => hasStream && 'visibility: hidden;'}
    justify-content: center;
    padding: 16px 24px;
    bottom: 34px;
    left: 0;
    right: 0;
  }
`;

const BottomPictureInfoText = styled.span`
  font-size: 10px;
  line-height: 15px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.screenSize.desktop} {
    color: ${({ theme }) => theme.color.white};
  }
`;

const FindingText = styled(ResizingText)`
  ${({ theme }) => theme.screenSize.desktop} {
    text-align: center;
  }
  ${({ theme }) => theme.screenSize.tablet} {
    ${({ theme }) => (theme.isRtl ? 'padding-left' : 'padding-right')}: 54px;
  }
`;

const ToolbarWrap = styled.div<{ hasStream: boolean }>`
  position: relative;
  flex-grow: 1;
  ${({ theme }) => theme.screenSize.tablet} {
    margin-top: ${TABLET_HEADER_HEIGHT};
    // MatchIntro height 만큼 margin-bottom 추가
    margin-bottom: ${({ hasStream }) => (hasStream ? '189px' : '210px')};
  }
`;

const Matching = () => {
  const peerAudioLevel = useAtomValue(peerAudioLevelAtom);
  const mobileLayout = useAtomValue(mobileLayoutAtom);
  const rootRef = useRef<HTMLDivElement | null>(null);
  const peerVidRef = useAtomValue(peerVidRefAtom);
  const step = useAtomValue(stepAtom);
  const { peerProfile } = useAtomValue(matchInfoAtom) || {};
  const clearMatch = useSetAtom(clearMatchAtom);
  const status = useAtomValue(statusAtom);
  const antmanCaptured = useAtomValue(antmanCapturedAtom);
  const { t } = useTranslation();
  const endMatchDisableShow = useAtomValue(endMatchDisableShowAtom);
  const swipeDisableSecond = useAtomValue(swipeDisableSecondAtom);
  const swipeDisableDuration = useAtomValue(swipeDisableDurationAtom);
  const matchStat = useAtomValue(matchStatAtom);
  const { isMobile } = getDeviceInfo();
  const [isPlayedAnimation, setIsPlayedAnimation] = useState(false);
  const peerCameraEnabled = useAtomValue(peerCameraEnabledAtom);
  const sourceStream = useAtomValue(sourceStreamAtom);
  const reportBeforeUnload = useSetAtom(reportBeforeUnloadAtom);
  const setIsUseKeepAlive = useSetAtom(isUseKeepAliveAtom);
  const { isLogin } = useLogin();

  useOpenEffectByUrlQuery();
  useInitDecoEffect();

  useBodyScrollLock(rootRef, step === 1);
  useEffect(() => {
    if (step === 2) {
      window.scrollTo(0, 0);
    }
  }, [step]);

  useEffect(() => {
    if (step === 1) return;
    return setIsPlayedAnimation(true);
  }, [step]);

  useEffect(
    () => () => {
      clearMatch();
    },
    [clearMatch]
  );

  useEffect(() => {
    if (!isLogin && status > STATUS.INITIAL) {
      clearMatch();
    }
  }, [clearMatch, isLogin, status]);

  const handleVisibilityChange = useCallback(() => {
    if (isMobile && status === STATUS.FINDING && document.hidden) {
      clearMatch();
    }
  }, [isMobile, status, clearMatch]);

  useVisibilityChange(handleVisibilityChange);

  const { endMatch, isKeyPressed: isEscKeyPressed } = useEndMatch();
  const { nextMatch, isKeyPressed: isArrowRightKeyPressed } = useNextMatch();

  const genderButtonProps: GenderButtonProps = {
    onSave: nextMatch,
    GenderIcon,
    landFrom: 'FINDING',
  };

  const locationButtonProps: LocationButtonProps = {
    onSave: nextMatch,
    LocationIcon: <LocationIcon />,
    landFrom: 'FINDING',
  };

  useMatchHeader();

  const device = useCssMediaDevice();
  const isDesktop = device === 'desktop';
  const headerTextMinFontSize = useMemo(
    () => (isDesktop ? 28 : 16),
    [isDesktop]
  );

  const onBeforeUnload = useCallback(async () => {
    setIsUseKeepAlive(true);
    reportBeforeUnload();

    /**
     * Firefox는 keepalive 지원하지 않기 때문에 browser unload에 딜레이 거는 방식으로 해결
     * https://stackoverflow.com/a/69764087
     */
    if (status === STATUS.MATCHED) {
      // 참고로 딜레이 비동기 동작은 유효하지 않음 (eg. await sleep(500)은 불가)
      const time = Date.now();
      while (Date.now() - time < 500);
      return true;
    }
  }, [reportBeforeUnload, status, setIsUseKeepAlive]);

  useGrantVideo();
  useBeforeunload(onBeforeUnload);

  const isOpenDecoModal = useIsOpenModal(ModalType.DECO_STUDIO);
  const isBlockedPeerVideo = useAtomValue(isBlockedPeerVideoAtom);

  const enableSkipConnecting = useEnableRemoteConfig('enableSkipConnecting');
  const isFinding =
    status === STATUS.FINDING ||
    (status === STATUS.CONNECTING && !!enableSkipConnecting);
  const isVideoObjectFitCover = useAtomValue(isVideoObjectFitCoverAtom);
  return (
    <Root step={step} status={status} tabIndex={0} role='button' ref={rootRef}>
      <Wrap status={status}>
        <VideoWrap step={step}>
          <MatchIntro />
          {step === 1 && (
            <>
              <BottomPictureInfoWrap
                hasStream={!!sourceStream}
                data-testid='Picture-Info'
              >
                <BottomPictureInfoText>
                  {t('DESC_IMGS_ONLY')}
                </BottomPictureInfoText>
              </BottomPictureInfoWrap>
              <BottomMatchCounter />
            </>
          )}
          <ToolbarWrap hasStream={!!sourceStream}>
            <Toolbar />
          </ToolbarWrap>
          <MyVideoPolygon isPlayedAnimation={isPlayedAnimation} />
          <VideoGap width={16} tabletWidth={0} mobileWidth={0} />
          <PeerVideoPolygon isPlayedAnimation={isPlayedAnimation}>
            {status <= STATUS.CONNECTING && <PeerHeroVideo status={status} />}
            {status === STATUS.MATCHED && <DesktopMatchUuid />}
            {!isOpenDecoModal && (
              <TabletTopHeader>
                <HeaderInner>
                  <HeaderItem>
                    {!!peerProfile && status === STATUS.MATCHED && (
                      <ConnectedPeerProfile peerProfile={peerProfile} />
                    )}
                  </HeaderItem>
                  <HeaderItem>
                    {!!peerProfile &&
                      status === STATUS.MATCHED &&
                      !antmanCaptured && (
                        <ReportButton data-testid='Match-Report-Button-Mobile' />
                      )}
                    <Gap width={12} />
                    <IcBack isShow={status !== STATUS.INITIAL} isSlide={false}>
                      <IcBackButton
                        id='Match-Back'
                        type='button'
                        onClick={endMatch}
                        data-testid='Match-Back-Mobile'
                      />
                      {endMatchDisableShow && (
                        <SwipeDisableLayer data-testid='Match-Back-Mobile-disable'>
                          <SwipeDisableSpinner
                            duration={swipeDisableDuration}
                          />
                          <SwipeDisableFiller duration={swipeDisableDuration} />
                          <SwipeDisableMask duration={swipeDisableDuration} />
                          <SwipeDisableText>
                            {swipeDisableSecond}
                          </SwipeDisableText>
                        </SwipeDisableLayer>
                      )}
                    </IcBack>
                  </HeaderItem>
                </HeaderInner>
              </TabletTopHeader>
            )}
            <Header>
              <HeaderInner>
                <HeaderText
                  delay={800}
                  isShow={isFinding}
                  isSlide
                  isFade
                  direction={
                    status <=
                    (enableSkipConnecting ? STATUS.CONNECTING : STATUS.FINDING)
                      ? 'down'
                      : 'up'
                  }
                  data-testid='Match-Finding-Text'
                >
                  <FindingText minFontSize={headerTextMinFontSize} maxLines={2}>
                    {t('VIDEOCHAT_MATCHING_DESC')}
                    <Dot index={0}>.</Dot>
                    <Dot index={1}>.</Dot>
                    <Dot index={2}>.</Dot>
                  </FindingText>
                </HeaderText>
                {!enableSkipConnecting && (
                  <HeaderText2
                    isShow={status === STATUS.CONNECTING}
                    isSlide
                    isFade
                  >
                    <FindingText
                      minFontSize={headerTextMinFontSize}
                      maxLines={2}
                    >
                      {t('VIDEOCHAT_MATCHING_DESC2')}
                      <Dot index={0}>.</Dot>
                      <Dot index={1}>.</Dot>
                      <Dot index={2}>.</Dot>
                    </FindingText>
                  </HeaderText2>
                )}
              </HeaderInner>
            </Header>
            <Hero isShow={step === 2 && isFinding}>
              <EarthAnimator
                mobileLayout={mobileLayout}
                delay={800}
                isShow={step === 2 && isFinding}
                isSlide={false}
                isGrow={true}
                isFade
              >
                <Picture data-testid='Match-Finding-Image'>
                  <source
                    srcSet='/images/match/imgEarth.webp'
                    type='image/webp'
                    width={456}
                    height={257}
                  />
                  <Image
                    src='/images/match/imgEarth.png'
                    alt='Profile'
                    width={456}
                    height={257}
                  />
                </Picture>
              </EarthAnimator>
              <BottomWrap isShow={step === 2 && isFinding}>
                <BottomWrapRow>
                  <GenderButton {...genderButtonProps} />
                  <BottomWrapGap />
                  <LocationButton {...locationButtonProps} />
                </BottomWrapRow>
              </BottomWrap>
            </Hero>
            <BottomWrapMobile isShow={isFinding}>
              <BottomWrapRow>
                <GenderButtonMobile {...genderButtonProps} />
                <BottomWrapGap />
                <LocationButtonMobile {...locationButtonProps} />
              </BottomWrapRow>
            </BottomWrapMobile>
            {!enableSkipConnecting &&
              status === STATUS.CONNECTING &&
              !!peerProfile && (
                <StartingPeerProfile
                  peerProfile={peerProfile}
                  mobileLayout={mobileLayout}
                />
              )}
            <PeerVideo
              isShow={status === STATUS.MATCHED}
              ref={peerVidRef}
              isObjectFitCover={isVideoObjectFitCover}
            />
            {status === STATUS.MATCHED &&
              (isDesktop || mobileLayout === 'HALF') && <Watermark />}
            {peerProfile && status === STATUS.MATCHED && (
              <VideoLayer
                cameraEnabled={peerCameraEnabled}
                src={peerProfile.profileImageUrl}
                audioLevel={peerAudioLevel}
                isLoading={
                  !matchStat?.getFirstFrameReceivedTimeMs() ||
                  isBlockedPeerVideo
                }
              />
            )}
            {antmanCaptured?.handled === false && (
              <AntmanWarning handleClickNext={nextMatch} />
            )}
            {status !== STATUS.MATCHED && <Background />}
          </PeerVideoPolygon>
        </VideoWrap>
        <DesktopMatchFooter
          endMatch={endMatch}
          nextMatch={nextMatch}
          isEscKeyPressed={isEscKeyPressed}
          isArrowRightKeyPressed={isArrowRightKeyPressed}
        />
      </Wrap>
      <ChattingContainer status={status} step={step} hide={!!antmanCaptured}>
        <ChatBar>
          <ChattingWrap step={step} status={status}>
            <DesktopChattingHeader>
              {!!peerProfile && status === STATUS.MATCHED && (
                <ConnectedPeerProfile peerProfile={peerProfile} />
              )}
              {!!peerProfile &&
                status === STATUS.MATCHED &&
                !antmanCaptured && (
                  <ReportButton data-testid='Match-Report-Button-Desktop' />
                )}
            </DesktopChattingHeader>
            <ChatList nextMatch={nextMatch} />
          </ChattingWrap>
        </ChatBar>
      </ChattingContainer>
    </Root>
  );
};

export default Matching;
