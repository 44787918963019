import { DecoEffectGroup, DecoGroupName } from 'src/types/Deco';

/**
 * 이펙트 그룹에 뉴뱃지 노출 여부 획득
 * 서버에 관련 추가 구현이 이루어지지 않아서 클라이언트 하드코딩으로 우선 진행
 *
 * @TODO 서버에서 그룹의 뉴뱃지 노출 관련 구현시 해당 로직 삭제
 */
export const isNewEffectGroup = (effectGroupId: DecoEffectGroup['groupId']) => {
  return effectGroupId === DecoGroupName.GESTURE;
};
