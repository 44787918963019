import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn, fadeOut, hide } from 'src/styles/animation';

import { hexToRgb } from 'src/utils/common';
import { hoverAndActiveCss } from 'src/utils/styled';

export const StringGuideDim = styled.div`
  width: 162px;
  height: 100vh;
  position: fixed;
  display: none;
  top: 0;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 0px;
  ${({ theme }) =>
    theme.isRtl
      ? `
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.3) 100%
      );
  `
      : `
  background: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
`}

  z-index: 2;
  pointer-events: none;
`;

const stringGuideSlideIn = ({ theme }: { theme: Theme }) => keyframes`
0% {
  transform: ${
    theme.isRtl
      ? `
    translate(24px, -50%);
  `
      : `
  translate(-24px, -50%);
  `
  }
} 100% {
  transform: translate(0, -50%);
}
`;

export const ToolButtonStringGuide = styled.p`
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 60px;

  top: 50%;
  transform: translateY(-50%);
  width: max-content;

  font-size: 14px;
  font-weight: 700;
  line-height: 15px;
  color: ${({ theme }) => theme.color.white};

  ${({ theme }) => theme.screenSize.tablet} {
    ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 48px;
    font-size: 13px;
  }

  animation:
    ${fadeIn} 0.8s ease-in-out forwards,
    ${stringGuideSlideIn} 0.8s ease-in-out forwards,
    ${fadeOut} 0.6s 3s ease-in-out forwards,
    // 애니메이션 모두 마친 후 스트링 가이드 제거 효과 (opacity만 변경시 다른 요소와 인터렉션 방해 가능)
    ${hide} 0.01s 3.6s forwards;
`;

const Rolling = ({ theme }: { theme: Theme }) => keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: ${
      theme.isRtl
        ? `
    translate(100%, 0);
  `
        : `
  translate(-100%, 0);
  `
    }
  }
`;

export const ToolbarContainer = styled.div`
  z-index: 11;
  position: absolute;
  top: 16px;

  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 16px;

  width: 48px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.screenSize.mobile} {
    gap: 16px;
  }

  ${({ theme }) => theme.screenSize.tablet} {
    width: 40px;
    top: 50%;
    align-items: center;
    transform: translateY(-50%);
    justify-content: center;
  }

  &:has(${ToolButtonStringGuide}) + ${StringGuideDim} {
    display: block;
    animation:
      ${fadeIn} 0.8s ease-in-out forwards,
      ${Rolling} 0.6s ease-in-out forwards reverse,
      ${fadeOut} 0.6s 3s ease-in-out forwards,
      ${hide} 0.01s 3.6s forwards;
  }
`;

export const ToolButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 4px;
  border-radius: 24px;
  background-color: ${({ theme }) =>
    `rgba(${hexToRgb(theme.color.black)}, 0.4)`};

  // 하위 레벨에 실제로 제공되는 버튼이 없는 경우
  &:not(:has(> :nth-child(1))) {
    display: none;
  }

  &:has(> :nth-child(2)) {
    padding: 10px 0;
    ${({ theme }) => theme.screenSize.mobile} {
      padding: 8px 0;
    }
  }
`;

export const ToolButtonIcon = styled.img`
  height: 24px;
  width: 24px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 22px;
    height: 22px;
  }
`;

export const ToolButton = styled.button<{ isAvailable?: boolean }>`
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  background-color: transparent;
  transition:
    opacity 0.5s ease-out,
    background-color 0.3s ease-out;

  ${hoverAndActiveCss(`
      img {
        opacity: 0.4;
      }
  `)}
  ${({ isAvailable = true }) =>
    !isAvailable &&
    `
    opacity: 0.4;
  `}

  ${({ theme }) => theme.screenSize.tablet} {
    width: 40px;
    height: 40px;
  }
`;

export const RedDot = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.color.red500};
  border-radius: 5px;
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 4px;
  top: 4px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 8px;
    height: 8px;
    ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 2px;
    top: 2px;
  }
`;
