import { useMemo } from 'react';

import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import RecentMatchCounter from 'src/components/Counter/RecentMatchCounter';
import Gap from 'src/components/Gap';
import { sourceStreamAtom } from 'src/stores/match/atoms';

const Container = styled.div<{ hasStream: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.color.gray30__dkGray50};
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 2;
  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
  ${({ theme }) => theme.screenSize.tablet} {
    height: 34px;
    border-radius: 16px 16px 0 0;
  }
  padding: 0 24px;
`;

const GreenDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.green500};
  flex-shrink: 0;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 8px;
    height: 8px;
    border-radius: 4px;
  }
`;

const Counter = styled(RecentMatchCounter)`
  font-size: 18px;
  color: ${({ theme }) => theme.color.white};
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
  }
`;

const Desc = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.color.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
  }
`;

const BorderLine = styled.div`
  position: absolute;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.gray60__dkGray100};
  width: calc(100% - 34px);
  left: 50%;
  transform: translateX(-50%);
  height: 1px;
`;

const BottomMatchCounter = () => {
  const { t } = useTranslation();
  const sourceStream = useAtomValue(sourceStreamAtom);
  const hasStream = useMemo(() => !!sourceStream, [sourceStream]);

  return (
    <Container hasStream={hasStream} data-testid='Match-Counter'>
      <GreenDot />
      <Gap width={8} mobileWidth={4} />
      <Counter />
      <Desc>&nbsp;{t('online__matching_now')}</Desc>
      <BorderLine />
    </Container>
  );
};

export default BottomMatchCounter;
