import { FC, useEffect } from 'react';

import styled from '@emotion/styled';

import Image from 'src/components/Image';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { mouseHoverCss } from 'src/utils/styled';
import { useSetAtom } from 'jotai';
import { isShowingCoachMarkAtom } from 'src/stores/match/atoms';

const CoachMarkWrap = styled.div`
  display: flex;
  width: 210px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;

  position: absolute;
  background-color: ${({ theme }) => theme.color.black};
  border-radius: 16px;
  // toolbar padding 만큼 위로 올라갸야함
  top: -10px;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 60px;
  ${({ theme }) => theme.screenSize.tablet} {
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
  }
`;
const CloseButton = styled.button`
  background-color: transparent;
  margin-bottom: 3px;
  cursor: pointer;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: -5px;
  ${({ theme }) => (theme.isRtl ? 'margin-left' : 'margin-right')}: -5px;

  ${({ theme }) =>
    mouseHoverCss(`
      border-radius: 50%;
      background-color: ${theme.color.gray100__dkGray150};
  `)}
`;
const GuideImage = styled(Image)`
  width: 178px;
  height: 118px;
  border-radius: 16px;
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
`;
const Description = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  overflow-wrap: anywhere;
  line-height: normal;
  color: ${({ theme }) => theme.color.white};
  margin-top: 8px;
  ${({ theme }) => theme.screenSize.tablet} {
    margin-top: 0;
  }
`;
const IcArrow = styled.img`
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: -10px;
  ${({ theme }) => theme.isRtl && 'transform: scaleX(-1);'};
  ${({ theme }) => theme.screenSize.desktop} {
    top: 24px;
  }
`;

interface Props {
  image: string;
  description: string;
  close: () => void;
}
const CoachMarks: FC<Props> = ({ image, description, close }) => {
  const media = useCssMediaDevice();
  const setIsShowingCoachMarks = useSetAtom(isShowingCoachMarkAtom);

  useEffect(() => {
    setIsShowingCoachMarks(true);
    return () => {
      setIsShowingCoachMarks(false);
    };
  }, [setIsShowingCoachMarks]);

  return (
    <CoachMarkWrap data-testid='Coach-Mark'>
      <IcArrow src='/images/deco/icBubbleArrow.svg' alt='Coach mark Arrow' />
      {media === 'desktop' && (
        <CloseButton
          id='Coach mark close'
          onClick={close}
          data-testid='Coach-Mark-Close-button'
        >
          <Image
            src='/images/deco/icCloseBold.svg'
            width={14}
            height={14}
            isBig={false}
            supportImageSet={false}
          />
        </CloseButton>
      )}
      {media === 'desktop' && (
        <GuideImage
          src={image}
          width={178}
          height={118}
          isBig={false}
          supportImageSet={false}
        />
      )}
      <Description>{description}</Description>
      {media !== 'desktop' && (
        <CloseButton
          id='Coach mark close'
          onClick={close}
          data-testid='Coach-Mark-Close-button'
        >
          <Image
            src='/images/deco/icCloseRegular.svg'
            width={14}
            height={14}
            isBig={false}
            supportImageSet={false}
          />
        </CloseButton>
      )}
    </CoachMarkWrap>
  );
};

export default CoachMarks;
