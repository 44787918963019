import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { activeEffectsAtom, effectsAtom } from 'src/stores/deco/atom';
import { EFFECT_STRING_GUIDE_TRANSLATION_KEYS } from 'src/types/Deco';
import { sleep } from 'src/utils/common';

const FADE_IN_OUT_DURATION_MILLISECONDS = 400;
const SHOW_DURATION_MILLISECONDS = 2500;

const Wrap = styled.div`
  width: 100%;
  padding: 0 24px;
  position: relative;
`;

const GuideText = styled.p<{ isShow: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
  transition: opacity ${FADE_IN_OUT_DURATION_MILLISECONDS}ms;

  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 15px;
    line-height: 20px;
  }
`;

interface Props {
  className?: string;
  ['data-testid']?: string;
}

const EffectStringGuide: FC<Props> = ({
  className,
  'data-testid': dataTestId,
}) => {
  const activeEffects = useAtomValue(activeEffectsAtom);
  const effects = useAtomValue(effectsAtom);
  const { t } = useTranslation();
  const [currentGuideText, setCurrentGuideText] = useState('');

  // fade out 효과를 위해 모든 가이드 문구는 렌더링 대상에 포함
  const potentialEffectGuideTexts = useMemo(() => {
    return effects
      .map(({ effectId }) => EFFECT_STRING_GUIDE_TRANSLATION_KEYS[effectId])
      .filter((translationKey) => !!translationKey)
      .map((translationKey) => t(translationKey));
  }, [effects, t]);

  const activeEffectGuideTexts = useMemo(() => {
    return potentialEffectGuideTexts.filter((guideText) => {
      return activeEffects.some(
        (activeEffect) =>
          t(EFFECT_STRING_GUIDE_TRANSLATION_KEYS[activeEffect.effectId]) ===
          guideText
      );
    });
  }, [potentialEffectGuideTexts, activeEffects, t]);

  useEffect(() => {
    let isCancelled = false;

    if (!activeEffectGuideTexts.length) {
      setCurrentGuideText('');
      return;
    }

    if (activeEffectGuideTexts.length === 1) {
      setCurrentGuideText(activeEffectGuideTexts[0]);
      return;
    }

    let currentGuideTextIndex = activeEffectGuideTexts.length - 1;
    setCurrentGuideText(activeEffectGuideTexts[currentGuideTextIndex]);

    const intervalId = setInterval(async () => {
      setCurrentGuideText('');
      await sleep(FADE_IN_OUT_DURATION_MILLISECONDS);
      if (isCancelled) return; // 중간 delay 사이에 cleanup 되었을 경우 고려
      currentGuideTextIndex =
        (currentGuideTextIndex + 1) % activeEffectGuideTexts.length;
      setCurrentGuideText(activeEffectGuideTexts[currentGuideTextIndex]);
    }, SHOW_DURATION_MILLISECONDS + FADE_IN_OUT_DURATION_MILLISECONDS);

    return () => {
      clearInterval(intervalId);
      isCancelled = true;
    };
  }, [activeEffectGuideTexts]);

  return (
    <Wrap className={className} data-testid={dataTestId}>
      {potentialEffectGuideTexts.map((guideText) => {
        return (
          <GuideText key={guideText} isShow={guideText === currentGuideText}>
            {guideText}
          </GuideText>
        );
      })}
    </Wrap>
  );
};

export default EffectStringGuide;
