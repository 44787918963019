import { useCallback, useEffect, useMemo } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import PermissionErrorFullModal from 'src/components/Match/Matching/PermissionErrorFullModal';
import useLogin from 'src/hooks/useLogin';
import {
  clearSourceStreamAtom,
  grantVideoAtom,
  sourceStreamAtom,
  statusAtom,
} from 'src/stores/match/atoms';
import useVisibilityChange from 'src/hooks/useVisibilityChange';
import { STATUS } from 'src/types/Match';
import getDeviceInfo from 'src/utils/device/info';

const useGrantVideo = () => {
  const grantVideo = useSetAtom(grantVideoAtom);
  const clearSourceStream = useSetAtom(clearSourceStreamAtom);
  const status = useAtomValue(statusAtom);
  const { isLogin } = useLogin();
  const { isMobile } = getDeviceInfo();
  const sourceStream = useAtomValue(sourceStreamAtom);
  const hasStream = useMemo(() => !!sourceStream, [sourceStream]);

  useEffect(() => {
    if (!isLogin) {
      return clearSourceStream();
    }
    grantVideo({
      errorModal: PermissionErrorFullModal,
    });
    return () => {
      clearSourceStream();
    };
  }, [clearSourceStream, grantVideo, isLogin]);

  const handleVisibilityChange = useCallback(() => {
    // 데스크톱에서는 visibility에 변화가 있어도, mic/camera 점유를 해제하지 않는다.
    if (!isMobile || !isLogin) return;

    if (hasStream && document.hidden && status === STATUS.INITIAL) {
      clearSourceStream();
    } else if (!hasStream && !document.hidden) {
      grantVideo({
        errorModal: PermissionErrorFullModal,
      });
    }
  }, [isMobile, hasStream, status, clearSourceStream, grantVideo, isLogin]);

  useVisibilityChange(handleVisibilityChange);
};

export default useGrantVideo;
