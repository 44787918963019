import { useAtomValue, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import {
  activeEffectsAtom,
  deactivateEffectAtom,
  handleUnsupportedDecoAccessAtom,
  isDecoSupportedAtom,
  isLoadingEffectRendererPipeAtom,
  loadingEffectIdSetAtom,
  selectDecoEffectAtom,
} from 'src/stores/deco/atom';
import { DecoEffect } from 'src/types/Deco';

const useEffectButton = (effect: DecoEffect) => {
  const isDecoSupported = useAtomValue(isDecoSupportedAtom);
  const isLoadingEffectRendererPipe = useAtomValue(
    isLoadingEffectRendererPipeAtom
  );
  const loadingEffectIdSet = useAtomValue(loadingEffectIdSetAtom);
  const activeEffects = useAtomValue(activeEffectsAtom);
  const selectEffect = useSetAtom(selectDecoEffectAtom);
  const deactivate = useSetAtom(deactivateEffectAtom);
  const handleUnsupportedDecoAccess = useSetAtom(
    handleUnsupportedDecoAccessAtom
  );

  const isActive = useMemo(
    () =>
      activeEffects.some(
        (activeEffect) => activeEffect.effectId === effect.effectId
      ),
    [activeEffects, effect]
  );
  const isLoading = useMemo(
    () =>
      isDecoSupported &&
      (isLoadingEffectRendererPipe || loadingEffectIdSet.has(effect.effectId)),
    [
      isDecoSupported,
      isLoadingEffectRendererPipe,
      loadingEffectIdSet,
      effect.effectId,
    ]
  );

  const withEffectAvailableCheck = (callbackOnAvailable: () => void) => {
    return () => {
      if (!isDecoSupported) {
        handleUnsupportedDecoAccess();
        return;
      }

      callbackOnAvailable();
    };
  };

  const activateEffect = () => {
    selectEffect(effect);
  };

  const deactivateEffect = () => {
    deactivate(effect);
  };

  return {
    isLoading,
    isAvailable: isDecoSupported,
    isActive,
    activateEffect,
    deactivateEffect,
    withEffectAvailableCheck,
  };
};

export default useEffectButton;
