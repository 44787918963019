import MatchView from 'src/views/Match';

export const getStaticProps = () => ({
  props: {
    canonical: true,
    metas: [
      {
        property: 'og:title',
        content: 'OG_TITLE',
        key: 'og:title',
      },
      {
        property: 'og:description',
        content: 'OG_DESC',
        key: 'og:description',
      },
      {
        name: 'description',
        content: 'OG_DESC',
        key: 'description',
      },
      {
        property: 'og:image',
        content: '/images/og/azarOgImage.png',
        key: 'og:image',
      },
    ],
  },
});

export default MatchView;
