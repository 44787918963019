import { FC } from 'react';

import { useAtomValue } from 'jotai';

import ObjectFitButton from 'src/components/Match/Matching/Toolbar/ObjectFitButton';
import {
  StringGuideDim,
  ToolButtonGroup,
  ToolbarContainer,
} from 'src/components/Match/Matching/Toolbar/styles';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { isShowToolbarAtom } from 'src/stores/deco/atom';
import { stepAtom } from 'src/stores/match/atoms';

import CameraOffButton from './CameraOffButton';
import DecoFilterButton from './DecoFilterButton';
import RandomEffectButton from './RandomEffectButton';
import ButtonWithGuide from './ButtonWithGuide';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

const Wrap = styled.div<{ isVisible: boolean }>`
  // 스트링 가이드 애니메이션 재트리거를 막기 위해 visibility로 툴바 노출여부 설정
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

const Toolbar: FC = () => {
  const cssDevice = useCssMediaDevice();
  const step = useAtomValue(stepAtom);
  const { t } = useTranslation();
  const isShowToolbar = useAtomValue(isShowToolbarAtom);

  return (
    <Wrap
      isVisible={isShowToolbar && (step === 1 || cssDevice !== 'mobile')}
      data-testid='Toolbar'
    >
      <ToolbarContainer>
        <ToolButtonGroup>
          {/* 카메라 오프 버튼은 빠른 시일 내에 제공되지 않을 예정이므로 스트링 가이드 스펙에서 제외 */}
          <CameraOffButton />
          <ButtonWithGuide guide={t('btn_guide_view_switch')}>
            <ObjectFitButton />
          </ButtonWithGuide>
        </ToolButtonGroup>
        <ToolButtonGroup>
          <ButtonWithGuide guide={t('btn_guide_effects')}>
            <DecoFilterButton />
          </ButtonWithGuide>
          <ButtonWithGuide guide={t('btn_guide_random_effect')}>
            <RandomEffectButton type='sidebar' />
          </ButtonWithGuide>
        </ToolButtonGroup>
      </ToolbarContainer>
      <StringGuideDim data-testid='Toolbar-String-Guide-Dim' />
    </Wrap>
  );
};

export default Toolbar;
