import { FC, useMemo, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAtomValue } from 'jotai';

import icGestureSvg from 'src/assets/images/icStudioGestureBold.svg';
import IcStyleSvg from 'src/assets/images/icStudioStyleBold.svg';
import IcBeautySvg from 'src/assets/images/icStudioBeautyBold.svg';
import IcBackgroundSvg from 'src/assets/images/icStudioBackgroundBold.svg';
import IcFilterSvg from 'src/assets/images/icStudioFilterBold.svg';

import TabletComponent from 'src/components/TabletComponent';
import { decoListAtom } from 'src/stores/deco/atom';
import {
  DecoGroupName,
  EFFECT_GROUP_DESCRIPTION_CROWDIN_KEY,
  decoEffectGroupNameCrowdinKey,
} from 'src/types/Deco';

import EffectButton from './EffectButton';
import { useTranslation } from 'react-i18next';
import { useAllowTouchMove } from 'src/hooks/useAllowTouchMove';
import { isNewEffectGroup } from './utils';
import NewBadgeBase from './NewBadge';
import EffectStringGuide from './EffectStringGuide';
import { createPortal } from 'react-dom';

const MobileFilterContainer = styled(TabletComponent)`
  z-index: 10;
  flex-direction: column;
  ${({ theme }) => theme.screenSize.tablet} {
    display: flex;
  }
`;
const DefaultEffectList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 18px 16px 26px 16px;
  box-sizing: border-box;

  display: flex;
  gap: 8px;

  overflow-x: scroll;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const GestureEffectList = styled(DefaultEffectList)`
  padding: 17px 16px;
`;

const MobileBottomTabArea = styled.div`
  display: flex;
  height: 56px;
  width: 100%;
  flex-shrink: 0;
`;
const IconBase = ({ theme }: { theme: Theme }) => css`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
  fill: ${theme.color.gray300__dkGray250};
`;
const IcGesture = styled(icGestureSvg)`
  ${IconBase}
`;
const IcStyle = styled(IcStyleSvg)`
  ${IconBase}
`;
const IcBeauty = styled(IcBeautySvg)`
  ${IconBase}
`;
const IcFilter = styled(IcFilterSvg)`
  ${IconBase}
`;
const IcBackground = styled(IcBackgroundSvg)`
  ${IconBase}
`;

const MobileBottomTab = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gray100__dkGray150};
  background-color: transparent;
  color: ${({ theme, isActive }) =>
    isActive ? theme.color.green500 : theme.color.gray300__dkGray250};
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  text-align: center;

  ${({ isActive, theme }) =>
    isActive &&
    `
    ${IcGesture}, ${IcBackground}, ${IcBeauty}, ${IcStyle}, ${IcFilter} {
      fill: ${theme.color.green500};
    }
  `}
`;

const GroupIconContainer = styled.div`
  position: relative;
`;

const NewBadge = styled(NewBadgeBase)`
  position: absolute;
  top: -6px;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: -19px;
  width: 14px;
  height: 14px;
  font-size: 8px;
`;

const MobileFilterHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0px 24px;
  gap: 8px;
`;

const MobileFilterTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

const MobileFilterDescription = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  margin-bottom: 8px;
`;

const MobileEffectStringGuide = styled(EffectStringGuide)`
  position: fixed;
  z-index: 11;

  top: calc(50% - 110px); // 이펙트 모달 외 남은 영역의 중앙
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.screenSize.desktop} {
    display: none;
  }
`;

const GROUP_TAB = [
  {
    icon: <IcGesture />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.GESTURE],
    groupId: DecoGroupName.GESTURE,
  },
  {
    icon: <IcStyle />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.STYLE],
    groupId: DecoGroupName.STYLE,
  },
  {
    icon: <IcBeauty />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.BEAUTY],
    groupId: DecoGroupName.BEAUTY,
  },
  {
    icon: <IcFilter />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.FILTER],
    groupId: DecoGroupName.FILTER,
  },
  {
    icon: <IcBackground />,
    name: decoEffectGroupNameCrowdinKey[DecoGroupName.BACKGROUND],
    groupId: DecoGroupName.BACKGROUND,
  },
];

const MobileEffects: FC = () => {
  const { t } = useTranslation();
  const decoList = useAtomValue(decoListAtom);
  const [activeGroupId, setActiveGroupId] = useState(GROUP_TAB[0].groupId);

  const effectListRef = useAllowTouchMove();

  const mobileList = useMemo(() => {
    const filterList = decoList.filter(
      (list) => list.groupId === activeGroupId
    );
    if (filterList.length > 0) {
      return filterList[0].effects;
    }

    return [];
  }, [activeGroupId, decoList]);

  const onClickTab = (groupId: DecoGroupName) => {
    setActiveGroupId(groupId);
  };

  const activeGroupName = useMemo(() => {
    const activeGroup = GROUP_TAB.find((v) => v.groupId === activeGroupId);
    if (activeGroup) return activeGroup.name;
    return null;
  }, [activeGroupId]);

  const activeGroupDescriptionKey =
    EFFECT_GROUP_DESCRIPTION_CROWDIN_KEY[activeGroupId];

  const EffectList =
    activeGroupId === DecoGroupName.GESTURE
      ? GestureEffectList
      : DefaultEffectList;

  return (
    <>
      <MobileFilterContainer data-testid='Mobile-Effect-Modal-Filters'>
        {activeGroupName && (
          <MobileFilterHeader>
            <MobileFilterTitle>{t(activeGroupName)}</MobileFilterTitle>
            {activeGroupDescriptionKey && (
              <MobileFilterDescription>
                {t(activeGroupDescriptionKey)}
              </MobileFilterDescription>
            )}
          </MobileFilterHeader>
        )}
        <EffectList ref={effectListRef}>
          {mobileList.map((effect) => (
            <EffectButton key={effect.effectId} effect={effect} />
          ))}
        </EffectList>
        <MobileBottomTabArea>
          {GROUP_TAB.map((item) => (
            <MobileBottomTab
              key={item.name}
              onClick={() => onClickTab(item.groupId)}
              isActive={activeGroupId === item.groupId}
              data-testid={`Mobile-Effect-Bottom-Tab-${item.groupId}`}
            >
              <GroupIconContainer>
                {item.icon}
                {isNewEffectGroup(item.groupId) && <NewBadge />}
              </GroupIconContainer>
              {t(item.name)}
            </MobileBottomTab>
          ))}
        </MobileBottomTabArea>
      </MobileFilterContainer>
      {/* 모달 하위 요소로 넣을 경우 모달 올라올 때 덜컥거리는 이슈 있어서 body로 이관 */}
      {activeGroupId === DecoGroupName.GESTURE &&
        createPortal(
          <MobileEffectStringGuide
            data-testid={'Mobile-Effect-String-Guide'}
          />,
          document.body
        )}
    </>
  );
};

export default MobileEffects;
